<template>
  <div class="courses">
    <div
      class="full-width pt-0 pt-md-10"
    >
      <div class="full-width d-flex flex-wrap justify-space-around px-4">
        <span
          v-for="blog in blogs"
          :key="`career-${blog.title}`"
          class="card-box-container pointer mb-6"
        >
          <VideoBox
            :title="blog.title"
            :content="blog.videoURL"
          />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import VideoBox from '@/components/VideoBox.vue'
import { db } from '@/firebaseSDK'

export default {
  name: 'Academy',

  data: () => ({
    blogs: [],
  }),

  components: {
    VideoBox
  },

  methods: {
    onCourseBoxClick (id) {
      this.$router.push(`/courses/${id}`)
    }
  },
  
  async mounted () {
    try {
      let resource = await db.collection("blogs").get()
      let blogs = resource.docs.map((doc) => doc.data())
      this.blogs = blogs
    } catch (err) {
      return err
    }
  }
}
</script>

<style scoped>
.card-box-container {
  max-height: 354px;
  max-width: 344px;
  width: -webkit-fill-available;
}
/* .courses {
}

.pointer{
  cursor: pointer;
}

.full-width {
  width: 100%;
} */
</style>
